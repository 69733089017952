
const ColumnNames = Object.freeze({
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  PROPERTY_STREET: 'PROPERTY_STREET',
  PROPERTY_HOUSE_NUMBER: 'PROPERTY_HOUSE_NUMBER',
  PROPERTY_CITY: 'PROPERTY_CITY',
  PROPERTY_ZIP: 'PROPERTY_ZIP',
  PROPERTY_PRICE: 'PROPERTY_PRICE',
  PROPERTY_PURCHASE_DATE: 'PROPERTY_PURCHASE_DATE'
})

export const ReadableColumnNames = Object.freeze({
  FIRST_NAME: 'Vorname',
  LAST_NAME: 'Nachname',
  EMAIL: 'E-Mail',
  PHONE: 'Telefonnummer',
  PROPERTY_STREET: 'Straße',
  PROPERTY_HOUSE_NUMBER: 'Hausnummer',
  PROPERTY_CITY: 'Stadt',
  PROPERTY_ZIP: 'PLZ',
  PROPERTY_PRICE: 'Kaufpreis',
  PROPERTY_PURCHASE_DATE: 'Kaufdatum'
})

export default ColumnNames
