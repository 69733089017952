
<template>
  <div class="container">
    <h3>
      Schritt 3: Vorschau
    </h3>
    <p class="mb-2">
      So werden die Daten aus Ihrer Datei importiert. Bitte sehen Sie sich den Import nocheinmal genau an, um sicherzustellen, dass die Daten korrekt sind:
    </p>

    <div
      class="mt-4 content-container flex-grow-1"
    >
      <v-tabs
        v-if="dataCounts"
        v-model="tab"
      >
        <v-tab>Valide Datensätze ({{ dataCounts.validRowsCount }})</v-tab>
        <v-tab>Invalide Datensätze ({{ dataCounts.invalidRowsCount }})</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="rows"
      >
        <v-tab-item>
          <PreviewTableFull
            :import-id="importId"
            :column-names="columnNames"
            :total-count="dataCounts.validRowsCount + dataCounts.invalidRowsCount "
            data-type="SUCCESS"
          />
        </v-tab-item>
        <v-tab-item>
          <PreviewTableFull
            :import-id="importId"
            :column-names="columnNames"
            :total-count="dataCounts.validRowsCount + dataCounts.invalidRowsCount "
            data-type="ERROR"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div
      class="justify-end d-flex align-end mt-4"
    >
      <v-btn
        text
        @click="handlePrevStep"
      >
        Zurück
      </v-btn>
      <v-btn
        text
        color="primary"
        type="button"
        :disabled="!dataCounts.validRowsCount"
        @click="submitUpload"
      >
        {{ dataCounts.validRowsCount }} valide Datensätze importieren
      </v-btn>
    </div>
  </div>
</template>

<script>
import PreviewTableFull from './PreviewTableFull.vue'

export default {
  components: { PreviewTableFull },
  props: {
    importId: {
      type: String,
      required: true
    },
    dataCounts: {
      type: Object,
      default: () => {}
    },
    columnNames: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    handlePrevStep () {
      this.$emit('prevStep')
    },
    submitUpload () {
      this.$emit('import')
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  min-height: 100px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding-bottom:0px;
  overflow: scroll;
}

</style>
