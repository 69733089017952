import ColumnNames from '../enums/ColumnNames'

export const csvHeaderStringMapper = {
  [ColumnNames.FIRST_NAME]: {
    validExpressions: ['vorname', 'rufname', 'first name', 'firstname', 'given name', 'givenname'],
    required: true
  },
  [ColumnNames.LAST_NAME]: {
    validExpressions: ['nachname', 'name', 'familienname', 'last name', 'lastname'],
    required: true
  },
  [ColumnNames.EMAIL]: {
    validExpressions: ['email', 'e-mail', 'mail', 'e-mail-adresse', 'email-adresse', 'emailadresse', 'e-mail adresse'],
    required: true
  },
  [ColumnNames.PHONE]: {
    validExpressions: ['telefon', 'telephon', 'tel', 'telephone', 'phone', 'telefonnummer', 'festnetz', 'handy', 'mobile'],
    required: false
  },
  [ColumnNames.PROPERTY_STREET]: {
    validExpressions: ['strasse', 'straße', 'street'],
    required: false
  },
  [ColumnNames.PROPERTY_HOUSE_NUMBER]: {
    validExpressions: ['hausnummer', 'nummer', 'number', 'house number', 'housenumber', 'nr', 'nr.'],
    required: false
  },
  [ColumnNames.PROPERTY_ZIP]: {
    validExpressions: ['postleitzahl', 'plz', 'zip', 'postal code'],
    required: false
  },
  [ColumnNames.PROPERTY_CITY]: {
    validExpressions: ['stadt', 'ort', 'wohnort', 'city', 'location'],
    required: false
  },
  [ColumnNames.PROPERTY_PRICE]: {
    validExpressions: ['kaufpreis', 'kauf preis', 'preis', 'purchase price', 'price'],
    required: false
  },
  [ColumnNames.PROPERTY_PURCHASE_DATE]: {
    validExpressions: ['kaufdatum', 'datum', 'date', 'purchase date'],
    required: false
  }
}
