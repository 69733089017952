<template>
  <div
    class="container"
  >
    <div
      class="import-step-container content-container red"
    >
      <v-icon
        color="white"
        class="mr-2"
        size="100"
      >
        mdi-alert-circle
      </v-icon>
      {{ importError }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    importError: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  color: white;
  min-height:100px;
  height: 100%;
  width: 100%;
  color:white
}
</style>
