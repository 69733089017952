<template>
  <div
    class="container"
  >
    <div
      v-if="!isDone"
      class="import-step-container content-container flex-grow-1"
      :style="cssPrimaryBackground"
    >
      <h4 class="mb-4">
        Daten werden zugeordnet ...
      </h4>
      <v-progress-circular
        :size="80"
        :width="7"
        indeterminate
        color="white"
      />
    </div>
    <div
      v-else
      class="import-step-container content-container flex-grow-1"
      :style="cssPrimaryBackground"
    >
      <h4 class="mb-4">
        Erfolgreich zugeordnet
      </h4>
      <v-icon
        color="white"
        class="mr-2"
        size="100"
      >
        mdi-check-circle
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDone: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssPrimaryBackground () {
      return {
        'background-color': this.$vuetify.theme.themes.light.primary
      }
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  color: white;
  min-height:100px;
  height: 100%;
  width: 100%;
}

</style>
